import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Header from './Header';
import Footer from './Footer';
import 'assets/stylesheets/application.scss';

const Layout = ({ children, className }) => {
  return (
    <div className='page-wrapper'>
      <Header />
      <div className={cx('layout-wrapper', [className])}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

Layout.defaultProps = {
  className: '',
};

export default Layout;
