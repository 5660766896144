import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image, Link } from 'components';
import { slideUp } from 'utils/animations';
import { isSamePath } from 'utils/helpers';

const Company = ({ bloc, className }) => {
  const { background, concise, companyWrapper: { company } } = bloc;
  const { name, area, slogan, text, description, url, logo, logoAlt, logoSecondary, logoSecondaryAlt } = company;
  const { isCta, title, buttonText, buttonTarget, buttonPage } = company;
  const logoStyle = { objectFit: 'contain', objectPosition: 'center left' };

  if (isCta) {
    let path = buttonPage.uri;
    if (isSamePath(buttonPage.uri) && buttonTarget) {
      path = buttonTarget;
    }
    else if (!isSamePath(buttonPage.uri) && buttonTarget) {
      const hash = buttonTarget.charAt(0) === '#' ? buttonTarget : `#${buttonTarget}`;
      path = `${buttonPage.uri}${hash}`;
    }

    return (
      <div className={cx('company-wrapper not-company', `bg-${background}`, [className])} {...slideUp}>
        <h5>{title}</h5>
        <Link to={path} anchor={buttonTarget && isSamePath(buttonPage.uri)} className='button'>{buttonText}</Link>
      </div>
    );
  }

  return (
    <div className={cx('company-wrapper', `bg-${background}`, [className])} {...slideUp}>
      <div className='company-logo'>
        <Image image={logo} alt={logoAlt || ''} className='logo-company' imgStyle={logoStyle} />
        <Image image={logoSecondary} alt={logoSecondaryAlt || ''} className='logo-secondary' imgStyle={logoStyle} />
      </div>
      <div className='info-wrapper'>
        {concise &&
          <>
            <h2 className='company-area'>{area}</h2>
            <p className='company-name'><i>{name}</i></p>
          </>
        }
        {!concise && <h2 className='company-name'>{name}</h2>}
        <h4 className='company-slogan'>{slogan}</h4>
        <p dangerouslySetInnerHTML={{ __html: concise ? text : description }} />
        {!concise && <Link to={url} external newTab className='button'>Visitar Website</Link>}
      </div>
    </div>
  );
};

Company.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Company.defaultProps = {
  className: '',
};

export default Company;
