import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

const Input = ({ id, name, label, placeholder, value, options, disabled, type, required, className }) => (
  <div className={cx('input-wrapper', [className])}>
    <label htmlFor={id}>{label}</label>
    { type === 'dropdown' &&
      <select id={id} name={name} disabled={disabled} required={required}>
        <option value='' disabled selected>{placeholder}</option>
        {options.map((option, idx) => <option key={idx} value={option.value}>{option.label}</option>)}
      </select>
    }
    { type === 'textarea' &&
      <textarea id={id} name={name} placeholder={placeholder} defaultValue={value} disabled={disabled} required={required} />
    }
    { type !== 'dropdown' && type !== 'textarea' &&
      <input id={id} name={name} type={type} placeholder={placeholder} defaultValue={value} disabled={disabled} required={required} />
    }
  </div>
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  disabled: false,
  type: 'text',
  required: false,
  className: '',
};

export default Input;
