import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slideshow = ({ children, slidesPerPage, options, className }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesPerPage,
    slidesToScroll: 1,
  };

  return (
    <div className={className}>
      <Slider {...settings} {...options}>{children}</Slider>
    </div>
  );
};

Slideshow.propTypes = {
  children: PropTypes.node.isRequired,
  slidesPerPage: PropTypes.number.isRequired,
  options: PropTypes.object,
  className: PropTypes.string.isRequired,
};

Slideshow.defaultProps = {
  slidesPerPage: 1,
  options: {},
  className: 'fvw',
};

export default Slideshow;
