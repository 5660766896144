import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { slideUp } from 'utils/animations';

const TextKeywords = ({ bloc, className }) => {
  const { text, keyword1, keyword2, keyword3 } = bloc;

  return (
    <div className={cx('text-keywords-wrapper', [className])} {...slideUp}>
      <div className='text-wrapper' dangerouslySetInnerHTML={{ __html: text }} />
      <div className='keywords-wrapper'>
        <div className='keyword'>{keyword1}</div>
        <div className='keyword'>{keyword2}</div>
        <div className='keyword'>{keyword3}</div>
      </div>
    </div>
  );
};

TextKeywords.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

TextKeywords.defaultProps = {
  className: '',
};

export default TextKeywords;
