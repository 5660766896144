import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Cookies from 'js-cookie';
import cx from 'classnames';

import { Image, Link } from 'components';
import { isEmail } from 'utils/helpers';

const Footer = ({ className }) => {
  const currentLanguage = Cookies.get('LAELanguage');
  const data = useStaticQuery(query);
  const info = data.cms[currentLanguage]?.mainInfo;
  const social = data.cms[currentLanguage]?.social;
  const footer = data.cms[currentLanguage]?.footer;
  const lae = data.cms[currentLanguage]?.lae;

  return (
    <>
      <footer className={cx('bg-footer', [className])}>
        <div className='footer-wrapper'>
          <div className='footer-contacts'>
            {footer?.contacts?.map(({ title, contact }, idx) => {
              const prefix = isEmail(contact) ? 'mailto' : 'tel';
              return <Link key={idx} to={`${prefix}:${contact}`} external>{contact}</Link>;
            })}
          </div>
          <div className='footer-addresses'>
            {footer?.addresses?.map(({ address }, idx) => (
              <p key={idx} dangerouslySetInnerHTML={{ __html: address }} />
            ))}
          </div>
          <div className='footer-terms-links mt-20 mb-20'>
            {footer?.termsLinks?.map(({ termLinkText, termLinkPage }, idx) => (
              <Link key={idx} to={termLinkPage.uri} className='mr-10 ml-10'>{termLinkText}</Link>
            ))}
          </div>
          <div className='copyright'>&copy; {new Date().getFullYear()}, {info?.copyright}</div>
          <div className='footer-social'>
            {social?.youtube &&
              <Link to={social.youtube} external newTab>
                <Image image={'icons/icon_youtube.svg'} alt={'Logo do Youtube'} />
              </Link>
            }
            {social?.facebook &&
              <Link to={social.facebook} external newTab>
                <Image image={'icons/icon_facebook.svg'} alt={'Logo do Facebook'} />
              </Link>
            }
            {social?.instagram &&
              <Link to={social.instagram} external newTab>
                <Image image={'icons/icon_instagram.svg'} alt={'Logo do Instagram'} />
              </Link>
            }
            {social?.linkedin &&
              <Link to={social.linkedin} external newTab>
                <Image image={'icons/icon_linkedin.svg'} alt={'Logo do LinkedIn'} />
              </Link>
            }
            {social?.twitter &&
              <Link to={social.twitter} external newTab>
                <Image image={'icons/icon_twitter.svg'} alt={'Logo do Twitter'} />
              </Link>
            }
          </div>
        </div>
      </footer>
      <div className='associated-logos-wrapper'>
        {lae?.enterprises?.map(({ className, url, logoAlt, logo }, idx) => (
          <Link key={idx} to={url} external newTab className={`link-logo ${className}`}>
            <Image image={logo} alt={logoAlt} imgStyle={{ objectFit: 'contain' }} />
          </Link>
        ))}
      </div>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;

const query = graphql`
  query FooterQuery {
    cms {
      en: siteOptions(language: EN) {
        mainInfo: siteOptions {
          copyright
        }
        social: siteOptions {
          facebook
          twitter
          youtube
          linkedin
          instagram
        }
        footer: siteOptions {
          contacts {
            contact
          }
          addresses {
            address
          }
          termsLinks {
            termLinkText
            termLinkPage {
              ... on WPGraphQL_Page {
                uri
              }
            }
          }
        }
        lae: siteOptions {
          enterprises {
            className
            url
            logoAlt
            logo {
              mediaItemUrl
              sourceUrl
              mediaItemId
              modified
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      pt: siteOptions(language: PT) {
        mainInfo: siteOptions {
          copyright
        }
        social: siteOptions {
          facebook
          twitter
          youtube
          linkedin
          instagram
        }
        footer: siteOptions {
          contacts {
            contact
          }
          addresses {
            address
          }
          termsLinks {
            termLinkText
            termLinkPage {
              ... on WPGraphQL_Page {
                uri
              }
            }
          }
        }
        lae: siteOptions {
          enterprises {
            className
            url
            logoAlt
            logo {
              mediaItemUrl
              sourceUrl
              mediaItemId
              modified
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
