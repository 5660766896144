export function isEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}


export function createLocalLink(url, cmsUrl) {
  return removeProtocol(url).replace(removeProtocol(cmsUrl), '');
}


export function isSamePath(newPath) {
  if (typeof window === 'undefined') return false; // because window is not defined on build
  return newPath.replace(/^\/|\/$/g, '') === window.location.pathname.replace(/^\/|\/$/g, '');
}


export function toLocaleDate(date, locale = 'pt') {
  return new Date(Date.UTC(date.slice(0,4), date.slice(4,6), date.slice(6,8))).toLocaleDateString(locale, { year: 'numeric', month: 'long', day: '2-digit' });
}


function removeProtocol(url) {
  return url.replace('https:', '').replace('http:', '');
}
