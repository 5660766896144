import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Cookies from 'js-cookie';

function SEO({ description, keywords, lang, meta, title, seoWidget }) {
  const currentLanguage = Cookies.get('LAELanguage');
  const data = useStaticQuery(query);
  const { cms, site } = data;
  const canonicalUrl = typeof window === 'undefined' ? site.siteMetadata.siteUrl : `${site.siteMetadata.siteUrl}${window.location.pathname}`;
  const metaTitle = seoWidget.title || title || cms[currentLanguage]?.mainInfo.siteName || site.siteMetadata.title;
  const metaDescription = seoWidget.description || description || site.siteMetadata.description;
  const metaKeywords = seoWidget.keywords || (keywords || site.siteMetadata.keywords).join(',');
  const imageUrl = (seoWidget.socialImage && seoWidget.socialImage.mediaItemUrl) || (cms[currentLanguage]?.mainInfo.socialImage && cms[currentLanguage]?.mainInfo.socialImage.mediaItemUrl);

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: canonicalUrl,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: 'google-site-verification',
          content: '',
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
      ].concat(
        imageUrl
          ? [
            {
              property: 'og:image',
              content: imageUrl,
            },
            {
              name: 'twitter:image',
              content: imageUrl,
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
          ]
          : [
            {
              name: 'twitter:card',
              content: 'summary',
            },
          ]
      ).concat(meta)}
    >
      <link rel='canonical' href={canonicalUrl} />
      <link rel='alternate' hreflang='pt-PT' href={canonicalUrl} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `pt`,
  meta: [],
  description: ``,
  seoWidget: {},
}

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  seoWidget: PropTypes.object,
}

export default SEO;

const query = graphql`
  query SEOQuery {
    cms {
      en: siteOptions(language: EN) {
        mainInfo: siteOptions {
          author
          description
          keywords
          siteName
          socialImage {
            mediaItemUrl
          }
        }
      }
      pt: siteOptions(language: PT) {
        mainInfo: siteOptions {
          author
          description
          keywords
          siteName
          socialImage {
            mediaItemUrl
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        keywords
      }
    }
  }
`;
