export const fade = {
  'data-sal': 'fade',
  'data-sal-delay': '50',
  'data-sal-duration': '200',
};


export const slideUp = {
  'data-sal': 'slide-up',
  'data-sal-delay': '100',
  'data-sal-duration': '400',
};
