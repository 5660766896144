import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'components';
import { form } from 'translations';

const FormShort = ({ className, language }) => {
  return (
    <div className={className}>
      <div className='form-cols'>
        <Input id='appt-email' name='appt-email' label={form[language].email.label} placeholder={form[language].email.placeholder} type='email' required />
        <Input id='appt-subject' name='appt-subject' label={form[language].subject.label} placeholder={form[language].subject.placeholder} type='text' />
      </div>
      <Input id='appt-message' name='appt-message' label={form[language].message.label} placeholder={form[language].message.placeholder} type='textarea' />
    </div>
  );
};

FormShort.propTypes = {
  className: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

FormShort.defaultProps = {
  className: '',
  language: 'pt',
};

export default FormShort;
