import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from './Link';

const CallToAction = ({ bloc, className }) => {
  const { displayAsButton, external, target, text, page, url } = bloc;
  const hash = target ? `#${target}` : '';
  const path = external ? url : page ? `${page.uri}${hash}` : '/#';

  return (
    <div className={cx('cta-wrapper', [className])}>
      <Link
        to={path}
        external={external}
        newTab={external}
        className={cx({ 'button': displayAsButton, 'pretty': !displayAsButton })}
      >
        {text}
      </Link>
    </div>
  );
};

CallToAction.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

CallToAction.defaultProps = {
  className: '',
};

export default CallToAction;
