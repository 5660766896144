import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Cookies from 'js-cookie';
import cx from 'classnames';

import { Image, Link } from 'components';
import { createLocalLink } from 'utils/helpers';
import { routes } from 'utils/routes';

const normalizeTranslationUris = (nodes) => {
  let normalizedUris = nodes.map((node) => {
    const current = node.translations[0]?.uri;
    const translated = node.translations[0]?.translations[0].uri;

    return { current, translated, isFrontPage: current === '/' };
  });

  return normalizedUris;
};

const Menu = ({ className }) => {
  const [active, toggleMenu] = useState(false);
  const data = useStaticQuery(query);
  const { slug } = data.cms.defaultLanguage;
  const { languages } = data.cms;
  const cmsUrl = data.cms.generalSettings.url;
  const translationUris = normalizeTranslationUris(data.cms.pages.nodes);
  const cookieName = 'LAELanguage';
  const currentLanguage = Cookies.get(cookieName) || slug;
  const menu = !!data.cms.menus.nodes.length
    ? data.cms.menus.nodes.filter((node) => node.slug === `main-menu-${currentLanguage}`)?.[0].menuItems.nodes
    : [];

  useEffect(() => {
    const pathLanguage = typeof window !== 'undefined' && window.location.pathname.split('/')[1];
    const isPathLanguage = languages.includes(pathLanguage);

    if (currentLanguage !== pathLanguage) {
      Cookies.set(cookieName, isPathLanguage ? pathLanguage : slug);
    }
  });

  const handleLanguageSwitch = (lang) => {
    Cookies.set(cookieName, lang);
  };

  return (
    <nav className={cx('nav-wrapper', [className])}>
      <button onClick={() => toggleMenu(!active)} className='burgermenu'>Open menu</button>
      <Link {...routes.home} className='brand-logo brand-mobile'>
        <Image image={'logo.svg'} alt='Leite Associated Enterprises logo' className='logo-regular' />
        <Image image={'logo_reverse.svg'} alt='Leite Associated Enterprises logo' className='logo-reverse' />
      </Link>
      <div className={cx('nav-menu', { active })}>
        {active &&
          <button onClick={() => toggleMenu(!active)} className='closemenu'>Close menu</button>
        }
        <Link {...routes.home} className='brand-logo'>
          <Image image={'logo.svg'} alt='Leite Associated Enterprises logo' className='logo-regular' />
          <Image image={'logo_reverse.svg'} alt='Leite Associated Enterprises logo' className='logo-reverse' />
        </Link>
        <div className='menu-wrapper'>
          {menu.map(({ databaseId, label, url }) => {
            const path = createLocalLink(url, cmsUrl);
            const isAnchor = path.charAt(0) === '#';
            return (
              <Link key={databaseId} to={path} anchor={isAnchor}>{label}</Link>
            )
          })}
          <div className='languages-wrapper'>
            {languages.map((language, idx) => {
              const targetPath = translationUris.filter(
                (translationUri) =>
                  typeof window !== 'undefined' &&
                    window.location.pathname === `${translationUri.isFrontPage ? '' : '/'}${translationUri.current}`
              )[0]?.translated || '';
              const hash = typeof window !== 'undefined' ? window.location.hash : '';

              return (
                language.slug === currentLanguage
                  ? <div key={idx} className='lang-option active'>{language.slug}</div>
                  : <Link key={idx} to={`${targetPath}${hash}`} className='lang-option' onClick={() => handleLanguageSwitch(language.slug)}>{language.slug}</Link>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;

const query = graphql`
  query MenuQuery {
    cms {
      defaultLanguage {
        slug
      }
      languages {
        slug
      }
      pages {
        nodes {
          translations {
            uri
            translations {
              uri
            }
          }
        }
      }
      menus {
        nodes {
          slug
          menuItems {
            nodes {
              databaseId
              label
              url
            }
          }
        }
      }
      generalSettings {
        url
      }
    }
  }
`;
