import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = ({ image, isExternal, alt, caption, className, ...props }) => {
  const data = useStaticQuery(query);
  const queryImage = data.images.edges.find(n => n.node.relativePath.includes(image));

  if (!image && !queryImage) { return null; }
  const isSVG = queryImage && queryImage.node.extension === 'svg';
  const fluidImage = queryImage ? queryImage.node : image.imageFile;
  const otherImage = isSVG ? fluidImage.publicURL : image;

  return (
    <figure className={className}>
      {isExternal || isSVG
        ? <img src={otherImage} alt={alt} {...props} />
        : fluidImage && fluidImage.childImageSharp &&
          <Img
            fluid={fluidImage.childImageSharp.fluid}
            alt={alt}
            placeholderStyle={{ visibility: 'hidden' }}
            {...props}
          />
      }
      {!!caption && caption.trim().length > 0 &&
        <figcaption>{caption}</figcaption>
      }
    </figure>
  );
};

Image.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  isExternal: PropTypes.bool.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string,
  className: PropTypes.string.isRequired,
};

Image.defaultProps = {
  isExternal: false,
  alt: '',
  className: '',
};

export default Image;

/* query all images on gatsby project */
const query = graphql`
  query FluidImageQuery {
    images: allFile {
      edges {
        node {
          extension
          relativePath
          publicURL
          childImageSharp {
            fluid(quality: 90, maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
