import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { fade } from 'utils/animations';

const Title = ({ bloc, className }) => {
  const { align, level, text } = bloc;
  const Element = level;

  return (
    <div className={cx('title-wrapper', [className])} {...fade}>
      <Element className={`t-${align}`}>{text}</Element>
    </div>
  );
};

Title.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Title.defaultProps = {
  className: '',
};

export default Title;
