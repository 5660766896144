const form = {
  'pt': {
    email: {
      label: 'Email: *',
      placeholder: 'escreva o seu email',
    },
    subject: {
      label: 'Assunto: *',
      placeholder: 'escreva o assunto',
    },
    message: {
      label: 'Mensagem:',
      placeholder: 'escreva aqui a sua mensagem',
    },
    feedback: {
      sending: 'A enviar a sua marcação...',
      success: 'Sucesso.',
      error: 'Ups, aconteceu algo:',
    },
    title: 'Será contactado/a brevemente para confirmação da consulta que solicitou.',
    thankYou: 'Obrigado.',
    confirm: 'Confirmar',
    mandatory: '* Campos obrigatórios',
    consent: 'Ao inserir os dados pessoais facultativos solicitados pelo presente formulário, o utilizador está a dar o seu consentimento expresso e esclarecido ao seu tratamento. Estes dados só podem ser utilizados para a finalidade específica de ser contactado por colaboradores da LAE.',
    send: 'Enviar mensagem',
  },
  'en': {
    email: {
      label: 'Email: *',
      placeholder: 'write your email',
    },
    subject: {
      label: 'Subject: *',
      placeholder: 'write the subject',
    },
    message: {
      label: 'Message:',
      placeholder: 'write your message here',
    },
    feedback: {
      sending: 'Sending your appointment...',
      success: 'Successful.',
      error: 'Ups, something happened:',
    },
    title: 'You will be contacted shortly to confirm the requested appointment.',
    thankYou: 'Thank you.',
    confirm: 'Confirm',
    mandatory: '* Mandatory fields',
    consent: 'By inserting the optional personal data requested by this form, the user is giving his express and informed consent to their treatment. These data can only be used for the specific purpose of being contacted by LAE employees.',
    send: 'Send message',
  },
};

export default form;
