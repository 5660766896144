import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import cx from 'classnames';

import { Link } from 'components';
import Menu from './Menu';

const Header = ({ className }) => {
  const data = useStaticQuery(query);
  const cookieName = 'LAECookie';
  const currentLanguage = Cookies.get('LAELanguage');
  const [hasConsent, setConsent] = useState(Cookies.get(cookieName) || false);
  const { analyticsScripts, consentMessage, consentButton, learnMoreLinks } = data.cms[currentLanguage]?.analytics || {};
  const learnMoreLinkActions = {
    action_page: { external: false, newTab: false },
    action_external: { external: true, newTab: true }
  };

  const addGTAG = () => {
    const dom = new DOMParser().parseFromString(analyticsScripts, 'text/html');
    const el = Array.from(
      dom.documentElement.querySelectorAll(':not(html):not(head):not(body)')
    );
    const nodeNames = el.map((node) => node.nodeName.toLowerCase());
    const attributes = el.map(({ async, src, text}) => ({ async, src, text }));

    return (
      <Helmet>
        {nodeNames.map((NodeName, idx) => {
          const { async, src, text } = attributes[idx];
          return (
            <NodeName key={idx} {...(!!async && { async: 'async' })} {...(!!src && { src })}>
              {!!text && text}
            </NodeName>
          );
        })}
      </Helmet>
    );
  };

  const renderLearnMoreLinks = () => {
    return (
      learnMoreLinks?.map(({ learnMoreAction, learnMoreText, learnMorePage, learnMoreUrl, learnMoreFile }, idx) => {
        const path = learnMoreAction === 'action_page' ? learnMorePage.uri : learnMoreUrl;
        return (
          <Link key={idx} to={path} {...learnMoreLinkActions[learnMoreAction]} className='ib ml-5'>
            {learnMoreText}.
          </Link>
        );
      })
    );
  };

  return (
    <header className={cx('header-menu', [className])}>
      {hasConsent && addGTAG()}
      <CookieConsent
        disableStyles
        location='bottom'
        buttonText={consentButton}
        cookieName={cookieName}
        containerClasses='cookie-consent-wrapper'
        buttonClasses='btn-consent'
        expires={150}
        onAccept={() => setConsent(true)}
      >
        {consentMessage}
        {renderLearnMoreLinks()}
      </CookieConsent>
      <Menu />
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default Header;

const query = graphql`
  query AnalyticsQuery {
    cms {
      en: siteOptions(language: EN) {
        analytics: siteOptions {
          analyticsScripts
          consentMessage
          consentButton
          learnMoreLinks {
            learnMoreAction
            learnMoreText
            learnMoreUrl
            learnMorePage {
              ... on WPGraphQL_Page {
                uri
              }
            }
          }
        }
      }
      pt: siteOptions(language: PT) {
        analytics: siteOptions {
          analyticsScripts
          consentMessage
          consentButton
          learnMoreLinks {
            learnMoreAction
            learnMoreText
            learnMoreUrl
            learnMorePage {
              ... on WPGraphQL_Page {
                uri
              }
            }
          }
        }
      }
    }
  }
`;
