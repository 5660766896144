import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Image, Link } from 'components';
import { fade, slideUp } from 'utils/animations';

const Cover = ({ bloc, className }) => {
  const { buttonPage, buttonTarget, buttonText, quote, reverseColors, showButton, showLogo, strapline, title } = bloc;
  const btnLink = showButton && (buttonTarget ? `${buttonPage.uri}#${buttonTarget}` : buttonPage.uri);
  if (typeof window !== 'undefined') {
    reverseColors
      ? document.documentElement.classList.add('cover-reverse')
      : document.documentElement.classList.remove('cover-reverse');
  }

  return (
    <div className={cx('cover-wrapper', [className], [bloc.className])} {...fade}>
      <header>
        {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
        {(showLogo || strapline || quote || showButton) &&
          <div className='card-quote' {...slideUp}>
            {showLogo &&
              <>
                <Image image={'logo.svg'} alt='Leite Associated Enterprises logo' className='brand-logo logo-regular' />
                <Image image={'logo_reverse.svg'} alt='Leite Associated Enterprises logo' className='brand-logo logo-reverse' />
              </>
            }
            {strapline && <p className='strapline'>{strapline}</p>}
            {quote && <blockquote dangerouslySetInnerHTML={{ __html: quote }} /> }
            {showButton && <Link to={btnLink} className='button'>{buttonText}</Link>}
          </div>
        }
      </header>
    </div>
  );
};

Cover.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Cover.defaultProps = {
  className: '',
};

export default Cover;
