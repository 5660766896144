import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Company } from 'components';

const CompanyGroup = ({ bloc, className }) => {
  const { companies } = bloc;
  const even = [];
  const odd = [];
  companies.forEach((c, idx) => { idx % 2 === 0 ? even.push(c) : odd.push(c) });

  return (
    <div className={cx('company-group-wrapper', [className])}>
      <div className='company-group-sticky'>
        {odd.map((company, idx) => <Company key={idx} bloc={company} />)}
      </div>
      <div className='company-group-sticky'>
        {even.map((company, idx) => <Company key={idx} bloc={company} />)}
      </div>
    </div>
  );
};

CompanyGroup.propTypes = {
  bloc: PropTypes.object.isRequired,
  className: PropTypes.string,
};

CompanyGroup.defaultProps = {
  className: '',
};

export default CompanyGroup;
